$primary: #5C2C5A !default;
$secondary-bg-color: #4067A1;
$primary-text-color: #272727;
$white: #ffffff;
.warning-text {
  color: red !important;
}
.table {
  th,
  td {
    vertical-align: middle;
  }
}
.warning-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  .warning-text {
    &.first {
      margin-bottom: 0;
    }
  }
  .warning-icon {
    display: inline-block;
    font-size: 60px !important;
    color: red;
    line-height: 60px;
  }

}
h3, h1, .h5 {
  color: $primary-text-color;
}

.table {
  color: $primary-text-color;
}

.c-sidebar {
  background: $secondary-bg-color;
  background: -webkit-linear-gradient(to top, $secondary-bg-color 0%, $primary 100%);
  background: -moz-linear-gradient(to top, $secondary-bg-color 0%, $primary 100%);
  background: linear-gradient(to top, $secondary-bg-color 0%, $primary 100%);
  .c-sidebar-nav-item {
    &.versions-side-nav-tab {
      .c-sidebar-nav-link {
        display: none;
        &.c-active {
          display: flex;
        }
      }
    }
  }
}

.c-sidebar-right {
  overflow: auto;
}

.c-sidebar-brand {
  justify-content: left;
  padding: 0.8445rem 1rem;
}

.card-body {
  color: #272727;
}

a {
  color: #434343;
}

.select-is-invalid > div {
  border-color: $form-feedback-invalid-color !important;
}

.c-header .c-header-toggler, .primary-text {
  color: $primary;
}
.subtree-instructions-wrapper {
  h5 {
    margin-bottom: 15px !important;
  }
  .subtree-instructions {
    margin: 0  !important;
  }
}
.bg-secondary {
  color: $white !important;
}

.card-header-action {
  color: $primary !important;
}

.logo-login {
  margin-bottom: 40px;
}

.card-body-login {
  padding: 0.5rem;
}

.bg-secondary-circle {
  background-color: $primary;

  .cis-user {
    color: $white;
  }
}

.c-body {
  background-color: $white;
}

.body-login {
  background: $secondary-bg-color;
  background: -webkit-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: -moz-linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
  background: linear-gradient(to right, $secondary-bg-color 0%, $primary 100%);
}

.dropdown-item {
  color: $primary;
}

.login-header {
  margin-bottom: 1.0rem;
}

.login-button {
  margin-top: 0.5rem;

  &:disabled {
    cursor: progress;
  }
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.options-dropdown {
  cursor: pointer;
  border-radius: 50% !important;

  &.show {
    background-color: $gray-200 !important;
  }

  &:hover {
    background-color: $gray-100;
  }

  .options-icon {
    padding: .5rem;
    font-size: .8rem;
    color: $gray-800;

    &:after {
      display: none !important;
    }
  }
}

.public-DraftEditor-content {
  min-height: 160px;
  max-height: 200px;
  overflow: auto;
}

.name-search {
  width: 30%;

  svg {
    left: 60px;
  }

  input {
    width: 80%;
    background-image: url("cis-search.svg");
    background-repeat: no-repeat;
    background-size: 15px auto;
    background-position: right 5px bottom 6px;
  }
}

.label-search {
  width: 30%;

  input {
    margin-left: 7px;
    width: 80%;
    background-image: url("cis-search.svg");
    background-repeat: no-repeat;
    background-size: 15px auto;
    background-position: right 5px bottom 6px;
  }
}

.created-search {
  svg {
    left: 153px;
  }
}

.hide-element {
  display: none;
}

.soft-text {
  color: #999;
}

.no-margin-svg {
  svg {
    margin: 0 !important;
  }
}

.choose-format-radio {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;

  li {
    display: flex;
    padding: 10px;
    align-items: center;

    input {
      padding: 10px;
    }

    label {
      margin: 0 0 0 10px;
    }
  }
}

.textarea-field-buffer {
  height: 53px;
  border: thin #ccc solid;
  border-bottom: none;
}

.textarea-field-input {
  font-family: Georgia, serif;
  color: #3c4b64;
  font-size: 16px;
  border: thin #ccc solid;
  border-top: none;
  width: 100%;
  height: 164px;
  margin-bottom: -5px;
  padding: 10px;
}

.choose-textFormat-radio {
  position: absolute;
  right: 25px;
  top: 0;
  list-style: none;
  display: flex;
  justify-items: center;

  li {
    display: flex;
    align-items: center;

    label {
      margin: 0 10px;
    }
  }
}

.metadata-form-group {
  position: relative;
}

.position-relative {
  position: relative;
}
.border-red {
  border: thin red solid;
}
.text-area-rich-text-editor {
  div[class*="InputPopover__checkOption___"] {
    display: none;
  }
}

.code-to-underline-class span {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNMy4zNDQgMTIuNjU2aDkuMzEzVjE0SDMuMzQ0di0xLjM0NHpNOCAxMS4zNDRxLTEuNjU2IDAtMi44MjgtMS4xNzJUNCA3LjM0NFYyaDEuNjU2djUuMzQ0cTAgLjk3LjY4OCAxLjY0VDggOS42NTh0MS42NTYtLjY3Mi42ODgtMS42NFYySDEydjUuMzQ0UTEyIDkgMTAuODI4IDEwLjE3MlQ4IDExLjM0NHoiLz48L3N2Zz4=") !important;
}

.show-answer-page-redirect-body {

  &.with-padding {
    h5 {
      margin-top: 1rem;
    }
  }

  h5 {
    color: $primary;
    font-size: 22px;
  }
}

.unsupported-end-after-sub-tree-choice-question {
  padding-right: 0;

  .unsupported-end-after-sub-tree-choice-question-span {
    display: flex;
    //border: thin #f00 solid;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

.with-code {
  .italic-editor-class,
  .bold-editor-class {
    display: none;
  }

  .code-editor-class {
    border-left-width: 1px !important;
  }
}

.with-italic,
.with-bold {
  .code-editor-class {
    display: none;
  }
}

.rich-text-editor-warning-wrapper {
  position: absolute;
  right: 0;
  padding-right: 15px;
  margin-right: 10px;
  list-style: none;

  li {
    color: red;
    text-align: right;
  }
}

.rhetorical-question-rich-text-editor {
  margin-top: 25px;
}

.rhetorical-question-button-wrapper {
  text-align: right;

  .rhetorical-question-button {
    margin-top: 8px;
  }
}
.named-row {
  &.highlighted {
    border: thin red solid;
    background-color: rgba(255,0,0,0.2);
  }
}
.meta-data-icon {
  max-height: 150px;
}

.collapsable-item {
  max-height: 0;
  overflow: hidden;
  margin: 0 !important;

  .collapsable-item-inner {
    font-size: 12px !important;
    color: $primary-text-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .collapsable-item-inner-title {
      display: flex;
      justify-content: space-between;
      &.no-subs {
        cursor: not-allowed;
      }
      &:hover {
        background-color: #DDDDDD;
      }
    }

    &.no-subs {
      cursor: not-allowed;
    }

    .collapsable-item-inner-list {

      pointer-events: none;
      transition: all 1s ease-out;
      transition-property: max-height, opacity;
      max-height: 0;
      color:$primary-text-color;
      opacity: 0;

      &.show-sub-items {
        transition: all 1s ease-in;
        transition-property: max-height, opacity;
        max-height: 1000px;
        opacity: 1;
        .collapsable-item-inner-list-unordered-list {
          pointer-events: all;
        }
      }
      .collapsable-item-inner-list-unordered-list {
        margin: 0;
      }
    }
  }

  opacity: 0;
  transition: all 1s ease-out;
  transition-property: max-height, opacity, margin;
  &.showing {
    max-height: 1000px;
    opacity: 1;
    transition: all 1s ease-in;

    margin:  15px 0 15px 0 !important;
    transition-property: max-height, opacity, margin;

  }
  .hidden-sub {
    display: none;
  }
}
.first-level-sub-children {
  &.with-children {

    cursor: pointer;
  }
  &.no-children {
    cursor: not-allowed;
  }
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: #CCCCCC;
  }
  .uk-hidden {
    display: none !important;
  }
}
.sub-tree-structure-viewer {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  .bubble {
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;

    border: thin green solid;
    background-color: green;
    &.green {
      border: thin darkmagenta solid;
      background-color: darkmagenta;
    }
    &.blue {
      border: thin blue solid;
      background-color: blue;
    }
    &.orange {
      border: thin orangered solid;
      background-color: orangered;
    }
    color: white;
    font-weight: 600;
    border-radius: 50%;
    line-height: 12px;
    font-size: 10px;
    min-width: 15px;
    min-height: 15px;
  }
}
.first-aid-meta-data {
margin-top: 20px;
  .meta-data-report {
    max-width: 400px;
    dl {
      display: flex;
      justify-content: space-between;

    }

  }
}
.first-aid-orphans {
  .orphaned-entity-report {
    max-width: 400px;
    dl {
      display: flex;
      justify-content: space-between;

    }
  }

}
.roll-back-to-version-button {
  cursor: pointer;
  &.submitting {
    cursor: progress;
  }
}

.comprehensive-flow-autocomplete {
  &.primary-autocomplete {
    margin-bottom: 10px;
  }
  &.secondary-autocomplete {
    margin-bottom: 25px;
  }
}
.no-padding-column {
  padding: 0 !important
}
